import React, { Dispatch, Fragment, FunctionComponent, RefObject, SetStateAction, useContext, useEffect, useState } from 'react'
import { CdcSearch, LastSearchesWidgetType } from '@bbx/common/types/UserFeed'
import { HorizontalSlider, ITEM_HEIGHT_JOBS } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { executeLastSearchAndNavigatorToResultList } from '@bbx/search-journey/common/lib/executeLastSearch'
import { css } from 'styled-components'
import { CdcAdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryDiscriminator'
import {
    DividerWithNegativeMargin,
    FeedWidgetWrapper,
} from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import SearchHistoryIcon from '@wh-components/icons/Searchhistory'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { getPulseAdId, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { Badge, BadgeColorType } from '@wh-components/core/Badge/Badge'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { deleteSearchHistoryEntryFromLink } from '@bbx/search-journey/common/api/cdcApiClient'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { toast } from '@wh-components/core/Toast/Toast'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { numberOfAdsLimit } from '@bbx/search-journey/sub-domains/start-page/components/widget/numberOfAdsLimit'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { verticalIdMap, verticalTaggingNameMap } from '@wh/common/chapter/types/verticals'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'

interface LastSearchesWidgetProps {
    widget: LastSearchesWidgetType
}

export const LastSearchesWidget: FunctionComponent<LastSearchesWidgetProps> = ({ widget }) => {
    const [localWidget, setLocalWidget] = useState<LastSearchesWidgetType>(widget)
    const [profileData] = useProfileData()

    useEffect(() => {
        setLocalWidget(widget)
    }, [widget])

    if (localWidget.searches.length === 0) {
        return null
    }

    return (
        <FeedWidgetWrapper
            heading={widget.title}
            icon={SearchHistoryIcon}
            backgroundColor="palette.jungle"
            linkText="zum Suchverlauf"
            href={isUserLoggedIn(profileData) ? '/iad/myprofile/mylastsearches' : undefined}
            id={`widget-index-${widget.listIndex}`}
            type={widget.type}
        >
            {localWidget.searches.map((search, index) => {
                return (
                    <Fragment key={`${search.keyword}${index}`}>
                        <LastSearchesSlider search={search} setWidget={setLocalWidget} />
                        {index !== widget.searches.length - 1 && <DividerWithNegativeMargin marginVertical="m" />}
                    </Fragment>
                )
            })}
        </FeedWidgetWrapper>
    )
}

const LastSearchesSlider: FunctionComponent<{
    search: CdcSearch
    setWidget: Dispatch<SetStateAction<LastSearchesWidgetType>>
}> = ({ search, setWidget }) => {
    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'LastSearchesWidget',
        adIds: search.ads.map((ad) => getPulseAdId(ad)).slice(0, numberOfAdsLimit),
        pulseMetadata: search.pulseMetadata,
    })
    const taggingData = useFeedEmptyTaggingData()
    const trackEvent = useOptimizelyTrack()
    const dismissContextLink = findContextLinkWithIdFromArray('dismiss', search.contextLinkList)

    const onClickShowAll = async () => {
        callActionEvent('ad_widget_show_all_click', taggingData, getWidgetAndPage(search.verticalId))
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(search.verticalId))
        trackEvent('lastSearchesWidgetInteraction')
        trackEvent('lastSearchesWidgetShowMoreClick')

        await executeLastSearchAndNavigatorToResultList(search)
    }

    const onClickShowAllButton = async () => {
        callActionEvent('ad_widget_show_all_button_click', taggingData, getWidgetAndPage(search.verticalId))
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(search.verticalId))
        trackEvent('lastSearchesWidgetInteraction')
        trackEvent('lastSearchesWidgetShowMoreClick')

        await executeLastSearchAndNavigatorToResultList(search)
    }

    const onClose = async () => {
        try {
            callActionEvent('startpage_dismiss_last_search_click', taggingData)
            await deleteSearchHistoryEntryFromLink(dismissContextLink!)

            setWidget((widget) => {
                return { ...widget, searches: widget.searches.filter((s) => s.timeId !== search.timeId) }
            })
        } catch (error) {
            toast(<ApiErrorAlert error={wrapInApiErrorIfNecessary(error).errorResponse} />, { type: 'error' })
        }
    }

    return (
        <HorizontalSlider
            heading={
                <LastSearchWidgetSubHeader
                    subHeading={search.keyword}
                    counter={search.infoText}
                    backgroundColor="palette.jungle"
                    onClick={onClickShowAll}
                />
            }
            testIdPrefix="last-searches"
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            onClick={onClickShowAll}
            onClickButton={onClickShowAllButton}
            onClose={dismissContextLink ? onClose : undefined}
            css={css`
                ${(p) => p.theme.media.only.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
            numberOfAdsLimit={numberOfAdsLimit}
            visibilityTrackingRef={visibilityTrackingRef}
            variant={search.verticalId === verticalIdMap.BAP ? 'portrait' : undefined}
            buttonHeight={search.verticalId === verticalIdMap.JOB ? ITEM_HEIGHT_JOBS : undefined}
        >
            {search.ads.slice(0, numberOfAdsLimit).map((ad, adIndex) => {
                return (
                    <CdcAdvertSummaryDiscriminator
                        ad={ad}
                        key={ad.id}
                        testIdPrefix="last-searches"
                        onClickTaggingEvent={() => {
                            callActionEvent('ad_widget_ad_click', taggingData, {
                                ...getWidgetAndPage(search.verticalId),
                                adIndex: adIndex + 1,
                                adId: getPulseAdId(ad),
                                listName: search.pulseMetadata?.listName,
                            })
                            callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(search.verticalId))
                            trackEvent('lastSearchesWidgetInteraction')
                            trackEvent('lastSearchesWidgetAdClick')
                        }}
                    />
                )
            })}
        </HorizontalSlider>
    )
}
const LastSearchWidgetSubHeader: FunctionComponent<{
    counter?: string
    backgroundColor?: BadgeColorType
    subHeading: string
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    onClick?: () => void
}> = ({ backgroundColor, counter, subHeading, visibilityTrackingRef, onClick }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']

    return (
        <Box display="flex" alignItems="center" ref={visibilityTrackingRef}>
            <Text
                lineClamp={1}
                fontWeight="bold"
                onClick={onClick}
                color={titleColor ?? 'palette.verydarkgrey'}
                css={css`
                    cursor: pointer;
                    &:active,
                    &:hover {
                        text-decoration: underline;
                    }
                `}
            >
                {subHeading}
            </Text>
            {counter && (
                <Badge paddingVertical="0" marginLeft="s" fontSize="xs" fontWeight="semibold" marginRight="s" color1={backgroundColor}>
                    {counter}
                </Badge>
            )}
        </Box>
    )
}

const getWidgetAndPage = (verticalId: number) => ({
    taggingId: verticalTaggingNameMap[verticalId],
    taggingWidgetType: 'LastSearchesWidget',
    source: 'homepage',
    page: 'Startpage',
})
