import React, { FunctionComponent } from 'react'
import { FeedWidgetWrapper } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { ContentAdSmallRenderSlot } from '@wh/common/digital-advertising/components/ContentAdSmallRenderSlot/ContentAdSmallRenderSlot'
import { Box } from '@wh-components/core/Box/Box'
import { BaseWidget } from '@bbx/common/types/UserFeed'

interface ContentAdWidgetProps {
    widget: BaseWidget
}

export const ContentAdWidget: FunctionComponent<ContentAdWidgetProps> = ({ widget }) => {
    return (
        <FeedWidgetWrapper display={{ tablet: 'none' }} type={widget.type}>
            <Box marginRight="m">
                <ContentAdSmallRenderSlot />
            </Box>
        </FeedWidgetWrapper>
    )
}
