import { PulseMetadata, SellerRecommendationWidgetType } from '@bbx/common/types/UserFeed'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { ContextLink, findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { AdvertSummarySkeletons } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import FavoriteHeartIcon from '@wh-components/icons/FavoriteHeart'
import { useOptimizelyDecision } from '@wh/common/chapter/hooks/optimizely'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react'
import { DividerWithNegativeMargin, FeedWidgetWrapper } from './FeedWidgetWrapper'
import { SellerRecommendationSlider } from './SellerRecommendationSlider'

interface Props {
    widget: SellerRecommendationWidgetType
}

export interface SellerRecommendation {
    id: number
    filterCount: number
    name: string
    contextLinks: ContextLink[]
    ads: AdvertSummary[]
}

interface SellerRecommendationResponse {
    rowsFound: number
    sellers: SellerRecommendation[]
}

export const SellerRecommendationWidget: FunctionComponent<Props> = ({ widget }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [isInView] = useIsInView(ref, '100px')

    const [status, setStatus] = useState<'initial' | 'loading' | 'error' | 'done'>('initial')
    const [sellerRecommendation, setSellerRecommendation] = useState<SellerRecommendationResponse | null>(null)

    const variation = useOptimizelyDecision('bbx_seller_recommendation_names')

    useEffect(() => {
        if (!isInView || status !== 'initial') {
            return
        }

        const contextLink = findContextLinkWithIdFromArray('sellerRecommendation', widget.contextLinkList)
        if (!contextLink) {
            setStatus('error')
            return
        }

        setStatus('loading')
        fetchFromContextLink<SellerRecommendationResponse>(contextLink)
            .then((response) => {
                setSellerRecommendation(response)
                setStatus('done')
            })
            .catch(() => {
                setStatus('error')
            })
    }, [isInView, status, widget.contextLinkList])

    if (status === 'error') {
        return null
    }

    if (status === 'done' && (!sellerRecommendation || sellerRecommendation.sellers.length === 0)) {
        return null
    }

    const variationTitle = `${(variation?.variables?.text as undefined | string) ?? ''}`

    const variationEnabled = variationTitle.trim() !== ''

    return (
        <FeedWidgetWrapper
            type="SELLER_RECOMMENDATION"
            icon={FavoriteHeartIcon}
            heading={variationEnabled ? variationTitle : widget.title}
            backgroundColor="palette.primary.main"
            visibilityTrackingRef={ref}
        >
            {status !== 'done' || !sellerRecommendation ? (
                Array.from({ length: widget.sellerCount }).map((_, index) => (
                    <Fragment key={index}>
                        <AdvertSummarySkeletons variant="portrait" attributeRows={3} />
                        {index !== widget.sellerCount - 1 && <DividerWithNegativeMargin marginVertical={13} />}
                    </Fragment>
                ))
            ) : (
                <SellerRecommendationWidgetContent sellerRecommendation={sellerRecommendation} pulseMetadata={widget.pulseMetadata} />
            )}
        </FeedWidgetWrapper>
    )
}

type SellerRecommendationWidgetContentProps = {
    sellerRecommendation: SellerRecommendationResponse
    pulseMetadata?: PulseMetadata
}

const SellerRecommendationWidgetContent: FunctionComponent<SellerRecommendationWidgetContentProps> = ({
    sellerRecommendation,
    pulseMetadata,
}) =>
    sellerRecommendation?.sellers.map((seller, index) => (
        <Fragment key={seller.id}>
            <SellerRecommendationSlider key={seller.id} sellerRecommendation={seller} pulseMetadata={pulseMetadata} index={index} />
            {index !== sellerRecommendation.sellers.length - 1 && <DividerWithNegativeMargin marginVertical="m" />}
        </Fragment>
    ))
