import React, { FunctionComponent } from 'react'
import { CdcAd, LastViewedAdsWidgetType } from '@bbx/common/types/UserFeed'
import { FeedWidgetWrapper, WidgetHeader } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { numberOfAdsLimit } from '@bbx/search-journey/sub-domains/start-page/components/widget/numberOfAdsLimit'
import EyeIcon from '@wh-components/icons/Eye'
import { getPulseAdId, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { LastViewedAdsSlider } from '@bbx/search-journey/common/components/Widgets/LastViewedAdsWidgetSlider'
import { verticalTaggingNameMap } from '@wh/common/chapter/types/verticals'

interface LastViewedAdsWidgetProps {
    widget: LastViewedAdsWidgetType
}

export const LastViewedAdsWidget: FunctionComponent<LastViewedAdsWidgetProps> = ({ widget }) => {
    const trackEvent = useOptimizelyTrack(undefined, { page: 'startpage' })
    const taggingData = useFeedEmptyTaggingData()
    const slider = widget.sliders?.[0]

    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'LastViewedAdsWidget',
        adIds: slider.ads.map((ad) => getPulseAdId(ad)).slice(0, numberOfAdsLimit),
        pulseMetadata: slider.pulseMetadata,
    })

    return (
        <FeedWidgetWrapper visibilityTrackingRef={visibilityTrackingRef} id={`widget-index-${widget.listIndex}`} type={widget.type}>
            <LastViewedAdsSlider
                slider={slider}
                onClickAdTagging={(ad: CdcAd, index: number) => {
                    trackEvent('startpageFirstWidgetAdClick')
                    trackEvent('startpageFirstWidgetInteraction')
                    callActionEvent('ad_widget_ad_click', taggingData, {
                        ...widgetAndPage,
                        taggingId: verticalTaggingNameMap[ad.verticalId],
                        adId: getPulseAdId(ad),
                        adIndex: index + 1,
                        listName: slider.pulseMetadata?.listName,
                    })
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                numberOfAdsLimit={numberOfAdsLimit}
                heading={<WidgetHeader icon={EyeIcon} backgroundColor="palette.primary.main" heading={widget.title} />}
            />
        </FeedWidgetWrapper>
    )
}

const widgetAndPage = {
    taggingWidgetType: 'LastViewedAdsWidget',
    source: 'homepage',
    page: 'Startpage',
}
