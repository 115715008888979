import { RecommendationWidgetType } from '@bbx/common/types/UserFeed'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { RecommendationsWidgetSlider } from '@bbx/search-journey/common/components/Widgets/RecommendationsWidgetSlider'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { FeedWidgetWrapper, WidgetHeader } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { numberOfAdsLimit } from '@bbx/search-journey/sub-domains/start-page/components/widget/numberOfAdsLimit'
import RecommendationsIcon from '@wh-components/icons/Recommendations'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import React, { Fragment, FunctionComponent } from 'react'
import { SellerRecommendationWidget } from './SellerRecommendationWidget'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

interface RecommendationWidgetProps {
    widget: RecommendationWidgetType
}

export const RecommendationWidget: FunctionComponent<RecommendationWidgetProps> = ({ widget }) => {
    const trackEvent = useOptimizelyTrack(undefined, { page: 'startpage' })
    const taggingData = useFeedEmptyTaggingData()

    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'RecommendationWidget',
        adIds: widget.ads.map((ad) => ad.id).slice(0, numberOfAdsLimit),
        pulseMetadata: widget.pulseMetadata,
    })

    const onClickShowAllTagging = () => {
        callActionEvent('recommendation_widget_show_all_click', taggingData, {
            ...widgetAndPage,
            listName: widget.pulseMetadata?.listName,
            transactionId: widget.pulseMetadata?.transactionId,
            widgetInteractionId: location.href,
            forcePulse: true,
        })
        trackEvent('startpageFirstWidgetInteraction')
        trackEvent('startpageFirstWidgetShowMoreClick')
        callActionEvent('ad_widget_show_all_click', taggingData, widgetAndPage)
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
    }

    const onClickHeader = () => {
        callActionEvent('recommendation_widget_header_click', taggingData, {
            ...widgetAndPage,
            listName: widget.pulseMetadata?.listName,
            transactionId: widget.pulseMetadata?.transactionId,
            widgetInteractionId: location.href,
            forcePulse: true,
        })
        trackEvent('startpageFirstWidgetInteraction')
        trackEvent('startpageFirstWidgetShowMoreClick')
        callActionEvent('ad_widget_show_all_click', taggingData, widgetAndPage)
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
    }

    const router = useRouter()

    return (
        <Fragment>
            <FeedWidgetWrapper visibilityTrackingRef={visibilityTrackingRef} type={widget.type}>
                <RecommendationsWidgetSlider
                    heading={
                        <WidgetHeader
                            icon={RecommendationsIcon}
                            href={buildSeoUrl(
                                findContextLinkWithIdFromArray('resultListSeoNavigatorLink', widget.contextLinkList)?.relativePath,
                            )}
                            onClick={onClickHeader}
                            backgroundColor="palette.primary.main"
                            heading={widget.title}
                        />
                    }
                    widget={widget}
                    onClickShowAllTagging={onClickShowAllTagging}
                    onClickShowAllButtonTagging={() => {
                        callActionEvent('recommendation_widget_show_all_button_click', taggingData, {
                            ...widgetAndPage,
                            listName: widget.pulseMetadata?.listName,
                            transactionId: widget.pulseMetadata?.transactionId,
                            widgetInteractionId: location.href,
                            forcePulse: true,
                        })
                        trackEvent('startpageFirstWidgetInteraction')
                        trackEvent('startpageFirstWidgetShowMoreClick')
                        callActionEvent('ad_widget_show_all_button_click', taggingData, widgetAndPage)
                        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                    }}
                    onClickAdTagging={(ad, index) => {
                        trackEvent('startpageFirstWidgetInteraction')
                        trackEvent('startpageFirstWidgetAdClick')
                        callActionEvent('recommendation_widget_ad_click', taggingData, {
                            ...widgetAndPage,
                            adId: ad.id,
                            adIndex: index + 1,
                            listName: widget.pulseMetadata?.listName,
                            transactionId: widget.pulseMetadata?.transactionId,
                        })
                        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                    }}
                    onClickArrowTagging={() => {
                        callActionEvent('recommendation_widget_arrow_click', taggingData, {
                            ...widgetAndPage,
                            listName: widget.pulseMetadata?.listName,
                            transactionId: widget.pulseMetadata?.transactionId,
                            widgetInteractionId: location.href,
                            forcePulse: true,
                        })
                    }}
                    numberOfAdsLimit={numberOfAdsLimit}
                />
            </FeedWidgetWrapper>
            {router.query.RANDOM_SELLER_COUNT && <SellerRecommenderTestClientOnly />}
        </Fragment>
    )
}

const SellerRecommenderTestClientOnly = dynamic(() => Promise.resolve(SellerRecommenderTest), {
    ssr: false,
})

const SellerRecommenderTest = () => {
    const router = useRouter()

    if (!router.query.RANDOM_SELLER_COUNT) {
        return null
    }

    return (
        <SellerRecommendationWidget
            widget={{
                type: 'SELLER_RECOMMENDATION',
                contextLinkList: [
                    {
                        description: '',
                        id: 'sellerRecommendation',
                        relativePath: `/search/sellers/random${location.search}`,
                        serviceName: 'iad',
                        uri: '',
                    },
                ],
                listIndex: 1,
                sellerCount: +router.query.RANDOM_SELLER_COUNT,
                title: '',
            }}
        />
    )
}

const widgetAndPage = {
    taggingWidgetType: 'RecommendationWidget',
    source: 'homepage',
    page: 'Startpage',
}
