import React, { FunctionComponent, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import PlusIcon from '@wh-components/icons/Plus'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import BapStartAza from '@bbx/static_hashed/img/bap/startpage/bap_start_aza.png'
import BapStartAzaX2 from '@bbx/static_hashed/img/bap/startpage/bap_start_aza@2x.png'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'

interface StartPageAzaWidgetProps {
    azaUrl: string
    taggingEvent: TaggingActionEvent
}

export const AzaRow: FunctionComponent<StartPageAzaWidgetProps> = ({ azaUrl, taggingEvent }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const backgroundColor = advertisingState.pageModifications.backgroundColors?.['startpage-adinsertion'] ?? 'palette.primary.main'
    const taggingData = useFeedEmptyTaggingData()

    return (
        <Box
            display="flex"
            flexDirection="column"
            height={{ tablet: '149px' }}
            position="relative"
            borderRadius="m"
            backgroundColor={{ tablet: backgroundColor }}
            paddingTop={{ phone: 'm', tablet: 'l' }}
            paddingLeft={{ tablet: 'm' }}
            paddingRight={{ phone: 'm' }}
            justifyContent="space-between"
            flex={1}
        >
            <Text color="white" display={{ phone: 'none', tablet: 'block' }} width="410px">
                Einfach und schnell verkaufen mit <PayliveryBadge id="paylivery-badge-aza-row" /> <br />
                (mit sicherer Onlinebezahlung und Premium-Versandservice).
            </Text>

            <ServerRoutingAnchorLink
                width="100%"
                type="button"
                color="secondary"
                href={azaUrl}
                testId="home-new-ad-link"
                marginBottom={{ tablet: 'm' }}
                maxWidth={{ tablet: '320px' }}
                onClick={() => callActionEvent(taggingEvent, taggingData)}
            >
                <PlusIcon size="small" color="palette.white" marginRight="xs" />
                Neue Anzeige aufgeben
            </ServerRoutingAnchorLink>
            <Box display={{ phone: 'none', desktop: 'fixed' }} position="absolute" right="16px">
                <LazyPicture
                    imgSrcSet={{
                        x1: BapStartAza,
                        x2: BapStartAzaX2,
                    }}
                    width="149px"
                    height="124px"
                />
            </Box>
        </Box>
    )
}
