import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { SpaceProps } from '@wh-components/system/space'
import { LayoutProps } from '@wh-components/system/layout'

export const SEOHeading: FunctionComponent<
    {
        nrOfAdverts: number | undefined
        titleColor: string | undefined
        titleCounterColor: string | undefined
        pageTitle: string
    } & LayoutProps &
        SpaceProps
> = ({ nrOfAdverts, titleColor, titleCounterColor, pageTitle, ...props }) => (
    <Text testId="seo-heading" {...props}>
        <Text as="h1" display="inline" color={titleColor ?? 'palette.primary.main'} fontSize="l" fontWeight="bold">
            {pageTitle}
        </Text>
        {nrOfAdverts ? (
            <Text color={titleCounterColor ?? 'palette.verydarkgrey'} testId="ad-type-all-ads-counter">
                <Text color={titleCounterColor ?? 'palette.koala'}> | </Text>
                {`${formatNumber(nrOfAdverts)} Anzeigen`}
            </Text>
        ) : null}
    </Text>
)
