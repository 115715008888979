import React, { FunctionComponent } from 'react'
import { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { LayoutProps } from '@wh/common/chapter/components/Layouts/LayoutProps'

type SiteMutationLayoutProps = Omit<LayoutProps, 'style'>

export const SiteMutationSkyscraperLayout: FunctionComponent<SiteMutationLayoutProps> & { appEmbedded: false } = (props) => (
    <Layout {...props} style="content-without-background-and-padding" showLeaderboard={false} showSkyscraper={true} />
)

SiteMutationSkyscraperLayout.appEmbedded = false
