import React, { useState } from 'react'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Input } from '@wh-components/core/FormElements/Input/Input'
import SearchIcon from '@wh-components/icons/Search'
import { Box } from '@wh-components/core/Box/Box'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'

export const AdIdSearch = () => {
    const [adIdInputValue, setAdIdInputValue] = useState<string>('')

    const redirectToAdDetail = () => {
        BbxRouter.push({ href: `/iad/object?adId=${adIdInputValue}`, clientSideRouting: false })
    }

    return (
        <Box
            position="fixed"
            left="985px"
            top="375px"
            width="300px"
            zIndex="popover"
            border="2px solid"
            borderColor="palette.primary.main"
            borderRadius="m"
            backgroundColor="palette.white"
            padding="s"
            css={css`
                @media only screen and (max-width: 1270px) {
                    display: none;
                }
            `}
        >
            <Heading level={3} text="willhaben-Code-Suche" marginBottom="-5px" />
            <Text fontSize="xs">(nur auf Testumgebungen sichtbar)</Text>
            <Input
                marginTop="s"
                id="devenv-adIdInput"
                testId="devenv-adIdInput"
                Icon={SearchIcon}
                type="number"
                size="small"
                value={adIdInputValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdIdInputValue(event.target.value)
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                        redirectToAdDetail()
                    }
                }}
                placeholder="Code eingeben"
                onIconClick={() => {
                    redirectToAdDetail()
                }}
            />
        </Box>
    )
}
