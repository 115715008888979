import { useMemo } from 'react'
import { useDigitalAdvertising } from '@wh/common/digital-advertising/hooks/useDigitalAdvertising'
import { RenderSlotId, RenderSlotIdConfig } from '@wh/common/digital-advertising/config'
import { testEnvForAdvertisingParameters } from '@wh/common/chapter/lib/config/runtimeConfig'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { useProfileParameters } from '@wh/common/digital-advertising/hooks/useProfileParameters'

const renderSlotIdConfig: RenderSlotIdConfig = {
    PHONE: [RenderSlotId.CONTENT_AD_SMALL],
    TABLET: [RenderSlotId.CONTENT_AD_MEDIUM],
    DESKTOP_WITHOUT_SKYSCRAPER: [RenderSlotId.CONTENT_AD_LARGE],
    DESKTOP_WITH_SKYSCRAPER: [RenderSlotId.SKYSCRAPER_LARGE, RenderSlotId.CONTENT_AD_LARGE],
}

export const useStartPageDigitalAdvertising = () => {
    const profileParameters = useProfileParameters()

    // we hardcode advertisingParameters because they never change and are not dynamic
    // we need to memoize to not cause infinite render cycles
    const advertisingParameters = useMemo(() => {
        if (profileParameters === 'unsure') {
            return undefined
        }
        return removeUndefined({
            pagetype: ['Startseite'],
            vertical: ['frontpage'],
            age: profileParameters.age,
            gender: profileParameters.gender,
            wh_uuid: profileParameters.wh_uuid,
            ...testEnvForAdvertisingParameters(),
        })
    }, [profileParameters])

    const dmpParameters = useMemo(() => {
        if (profileParameters === 'unsure') {
            return undefined
        }
        return removeUndefined({
            pagetype: 'Startseite',
            vertical: 'frontpage',
            age: profileParameters.age,
            gender: profileParameters.gender,
        })
    }, [profileParameters])

    const dmpUserIdentities = useMemo(() => {
        if (profileParameters === 'unsure') {
            return undefined
        }
        return {
            wh_uuid: profileParameters.wh_uuid,
            email_sha256: profileParameters.emailSha256Hash,
        }
    }, [profileParameters])
    return useDigitalAdvertising(renderSlotIdConfig, advertisingParameters, dmpParameters, dmpUserIdentities)
}
