import { TeaserWidgetType } from '@bbx/common/types/UserFeed'
import { ArrowIconButton, HoverContainer } from '@bbx/search-journey/common/components/ImageGallery/VanillaImageGallery/ArrowIconButton'
import { useVanillaImageGallery } from '@bbx/search-journey/common/components/ImageGallery/VanillaImageGallery/useVanillaImageGallery'
import { SEOHeading } from '@bbx/search-journey/common/components/SEOHeading/SEOHeading'
import { Box } from '@wh-components/core/Box/Box'
import { SystemValue } from '@wh-components/system'
import { DisplayProps } from '@wh-components/system/layout'
import { hiddenScrollbar } from '@wh/common/chapter/components/hiddenScrollbar'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { ContentAdLargeRenderSlot } from '@wh/common/digital-advertising/components/ContentAdLargeRenderSlot/ContentAdLargeRenderSlot'
import { ContentAdMediumRenderSlot } from '@wh/common/digital-advertising/components/ContentAdMediumRenderSlot/ContentAdMediumRenderSlot'
import React, { Fragment, FunctionComponent, useContext } from 'react'
import { css } from 'styled-components'
import { TeaserImage } from './TeaserImage'
import { werbungBeforeCssSitemutation } from '@wh/common/digital-advertising/components/werbungBeforeCss'

interface TeaserProps {
    teaserWidget: TeaserWidgetType
    nrOfAdverts?: number
}

export const Teaser: FunctionComponent<TeaserProps> = ({ nrOfAdverts, teaserWidget }) => {
    const { scrollContainerRef, imageRef, interval, handlePictureChange } = useVanillaImageGallery(teaserWidget.images.length, 4000)

    const advertisingState = useContext(AdvertisingStateContext)
    const startPageTeaserIFrame = advertisingState.pageModifications.startPageTeaserIFrame
    const startPageLeaderboardIFrameSmall = advertisingState.pageModifications.startPageLeaderboardIFrameSmall
    const startPageTeaserIFrameSmall = advertisingState.pageModifications.startPageTeaserIFrameSmall

    const replaceTeaserWithIframe =
        startPageTeaserIFrame !== undefined && (startPageTeaserIFrameSmall !== undefined || startPageLeaderboardIFrameSmall !== undefined)

    return (
        <Box marginTop={{ tablet: 's' }}>
            {!replaceTeaserWithIframe && (
                <Fragment>
                    <SEOHeading
                        nrOfAdverts={nrOfAdverts}
                        pageTitle="Das größte Anzeigenportal Österreichs"
                        titleColor={undefined}
                        titleCounterColor={undefined}
                        display={{ phone: 'none', tablet: 'inline' }}
                    />
                    <Box height={{ tablet: '16px' }} />
                </Fragment>
            )}
            <Box display="flex">
                {replaceTeaserWithIframe ? (
                    <Box
                        width="100%"
                        css={css`
                            ${werbungBeforeCssSitemutation}
                        `}
                    >
                        <IFrameTeaser display={{ phone: 'none', tablet: 'block' }} src={startPageTeaserIFrame?.src} height="100%" />
                        <IFrameTeaser
                            display={{ phone: 'block', tablet: 'none' }}
                            src={startPageLeaderboardIFrameSmall?.src}
                            height={startPageLeaderboardIFrameSmall?.height}
                        />
                        <IFrameTeaser
                            display={{ phone: 'block', tablet: 'none' }}
                            src={startPageTeaserIFrameSmall?.src}
                            height={startPageTeaserIFrameSmall?.height}
                        />
                    </Box>
                ) : (
                    <HoverContainer
                        position="relative"
                        display="flex"
                        flex={1}
                        overflow="hidden"
                        borderRadius={{ mobile: '0px', tablet: '4px' }}
                    >
                        <ArrowIconButton
                            direction="left"
                            onClick={() => {
                                handlePictureChange('previous')
                                clearInterval(interval.current)
                            }}
                            testId="button-image-previous"
                        />
                        <Box
                            position="relative"
                            ref={scrollContainerRef}
                            display="flex"
                            flex={1}
                            overflow="auto"
                            css={css`
                                ${hiddenScrollbar}

                                scroll-snap-type: x mandatory;
                            `}
                        >
                            {teaserWidget.images.map((image, index) => (
                                <TeaserImage
                                    key={index}
                                    index={index}
                                    image={image}
                                    mobileHeight={teaserWidget.mobileHeight ?? 146}
                                    imageRef={imageRef}
                                />
                            ))}
                        </Box>
                        <ArrowIconButton
                            direction="right"
                            onClick={() => {
                                handlePictureChange('next')
                                clearInterval(interval.current)
                            }}
                            testId="button-image-next"
                        />
                    </HoverContainer>
                )}
                <Box width={300} marginLeft="m" display={{ phone: 'none', tablet: 'block' }}>
                    <ContentAdLargeRenderSlot />
                    <ContentAdMediumRenderSlot />
                </Box>
            </Box>
        </Box>
    )
}

const IFrameTeaser: FunctionComponent<{ src?: string; height?: SystemValue } & DisplayProps> = ({ height, src, ...props }) => {
    if (!src) {
        return null
    }

    return (
        <Box width="100%" height={height} {...props}>
            <iframe
                src={src}
                frameBorder="0"
                title="Werbung"
                aria-hidden="true"
                css={css`
                    display: block;
                    width: 100%;
                    height: 100%;

                    ${(p) => p.theme.media.print} {
                        display: none;
                    }
                `}
            />
        </Box>
    )
}
