import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { NearbyWidgetPlaceholderType, NearbyWidgetType } from '@bbx/common/types/UserFeed'
import { Box } from '@wh-components/core/Box/Box'
import { HorizontalSlider } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { css } from 'styled-components'
import { getCurrentPosition, toastLocationError } from '@bbx/search-journey/common/lib/getLocation'
import { getNearbyWidget } from '@bbx/search-journey/common/api/cdcApiClient'
import { AdvertSummarySkeleton } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { GEO_LOCATION_GRANTED } from '@wh/common/chapter/lib/localStorage'
import { Button } from '@wh-components/core/Button/Button'
import { CdcAdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryDiscriminator'
import { FeedWidgetWrapper, WidgetHeader } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import PinIcon from '@wh-components/icons/Pin'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import NearbySmallX1 from '@bbx/static_hashed/img/startpage/nearby-map/near_me_map_S.png'
import NearbySmallX2 from '@bbx/static_hashed/img/startpage/nearby-map/near_me_map_S@2x.png'
import NearbyLargeX1 from '@bbx/static_hashed/img/startpage/nearby-map/near_me_map_L.png'
import NearbyLargeX2 from '@bbx/static_hashed/img/startpage/nearby-map/near_me_map_L@2x.png'
import { Text } from '@wh-components/core/Text/Text'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { numberOfAdsLimit } from '@bbx/search-journey/sub-domains/start-page/components/widget/numberOfAdsLimit'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

interface NearbyWidgetProps {
    widgetPlaceholder: NearbyWidgetPlaceholderType
}

const isNearbyWidgetType = (object: NearbyWidgetType | 'permission-denied' | 'unknown' | 'not-granted'): object is NearbyWidgetType =>
    typeof object === 'object'

export const NearbyWidget: FunctionComponent<NearbyWidgetProps> = ({ widgetPlaceholder }) => {
    const [widget, setWidget] = useState<NearbyWidgetType | 'permission-denied' | 'unknown' | 'not-granted'>('unknown')
    const advertisingState = useContext(AdvertisingStateContext)
    const taggingData = useFeedEmptyTaggingData()
    const backgroundColor = advertisingState.pageModifications.backgroundColors?.['startpage-nearby-widget'] ?? 'palette.polarbear'
    const borderColor = advertisingState.pageModifications.borders?.['startpage-nearby-widget'] ?? 'palette.owl'

    const promptLocationAndSearch = () => {
        getCurrentPosition(
            async (lat, lon) => {
                const localWidget = await getNearbyWidget(lat, lon)
                setWidget(localWidget)
                if (storageAvailable('sessionStorage')) {
                    sessionStorage.setItem(GEO_LOCATION_GRANTED, 'granted')
                }
            },
            (code) => {
                setWidget('permission-denied')
                if (storageAvailable('sessionStorage')) {
                    sessionStorage.setItem(GEO_LOCATION_GRANTED, 'denied')
                }
                toastLocationError(code)
            },
        )
    }

    useEffect(() => {
        const loadNearbyWidget = async () => {
            const grantStatus = await getLocationGrantStatus()

            if (grantStatus === 'prompt') {
                setWidget('not-granted')
                return
            } else if (grantStatus === 'denied') {
                setWidget('permission-denied')
                return
            } else if (grantStatus === 'granted') {
                promptLocationAndSearch()
            }
        }

        loadNearbyWidget()
    }, [])

    if (widget === 'not-granted' || widget === 'permission-denied') {
        return (
            <FeedWidgetWrapper id={`widget-index-${widgetPlaceholder.listIndex}`} type="NEARBY_PLACEHOLDER">
                <WidgetHeader icon={PinIcon} backgroundColor="palette.primary.main" heading={widgetPlaceholder.title} />
                <Box
                    display="flex"
                    flexDirection={{ phone: 'column', tablet: 'row' }}
                    paddingRight={{ phone: 'm' }}
                    marginTop="m"
                    border={{ tablet: '1px solid' }}
                    borderColor={{ tablet: borderColor }}
                    borderRadius={{ tablet: 'm' }}
                    backgroundColor={{ tablet: backgroundColor }}
                >
                    <Box
                        height={120}
                        width="100%"
                        flex={{ tablet: '50%' }}
                        css={css`
                            background: image-set(url('${NearbySmallX1}') 1x, url('${NearbySmallX2}') 2x) no-repeat;
                            background-position: center, center;
                            ${(p) => p.theme.media.tablet} {
                                background: image-set(url('${NearbyLargeX1}') 1x, url('${NearbyLargeX2}') 2x) no-repeat;
                            }
                        `}
                    />
                    <Box paddingLeft={{ tablet: 'm' }} paddingTop={{ phone: 's', tablet: 'l' }} flex="50%">
                        <Text as="p">Gib deinen Standort bekannt und finde Anzeigen in deiner Nähe!</Text>
                        <Button
                            marginTop="s"
                            paddingHorizontal="xxl"
                            width={{ phone: '100%', tablet: 'auto' }}
                            onClick={() => {
                                callActionEvent('startpage_nearby_request_location', taggingData)
                                promptLocationAndSearch()
                            }}
                        >
                            Jetzt Standort freigeben
                        </Button>
                    </Box>
                </Box>
            </FeedWidgetWrapper>
        )
    }

    if (isNearbyWidgetType(widget) && widget.ads.length === 0) {
        return null
    }

    if (widget === 'unknown') {
        return (
            <FeedWidgetWrapper type="NEARBY_PLACEHOLDER">
                <HorizontalSlider
                    testIdPrefix="nearby-widget-skeleton"
                    isLoading={true}
                    css={css`
                        ${(p) => p.theme.media.only.tablet} {
                            margin-right: -${(p) => p.theme.space.m}px;
                        }
                    `}
                >
                    {Array.from({ length: 5 }).map((_, index) => (
                        <AdvertSummarySkeleton key={index} variant="portrait" />
                    ))}
                </HorizontalSlider>
            </FeedWidgetWrapper>
        )
    }

    return <NearbyWidgetSlider widget={widget} widgetPlaceholder={widgetPlaceholder} taggingData={taggingData} />
}

const NearbyWidgetSlider: FunctionComponent<{
    widget: NearbyWidgetType
    widgetPlaceholder: NearbyWidgetPlaceholderType
    taggingData: TaggingData
}> = ({ widget, widgetPlaceholder, taggingData }) => {
    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'NearbyWidget',
        adIds: widget.ads.map((ad) => ad.id).slice(0, numberOfAdsLimit),
        pulseMetadata: widgetPlaceholder.pulseMetadata,
    })
    const trackEvent = useOptimizelyTrack()

    const onClickLink = () => {
        callActionEvent('ad_widget_show_all_click', taggingData, widgetAndPage)
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
        trackEvent('nearbyWidgetInteraction')
        trackEvent('nearbyWidgetShowMoreClick')
    }
    return (
        <FeedWidgetWrapper
            visibilityTrackingRef={visibilityTrackingRef}
            id={`widget-index-${widgetPlaceholder.listIndex}`}
            type="NEARBY_PLACEHOLDER"
        >
            <HorizontalSlider
                testIdPrefix="nearby-widget"
                heading={
                    <WidgetHeader
                        icon={PinIcon}
                        backgroundColor="palette.primary.main"
                        heading={widgetPlaceholder.title}
                        href={buildSeoUrl(
                            findContextLinkWithIdFromArray('resultListSeoNavigatorLink', widget.contextLinkList)?.relativePath,
                        )}
                        onClick={onClickLink}
                    />
                }
                linkText="Alle anzeigen"
                buttonText="Alle anzeigen"
                href={buildSeoUrl(findContextLinkWithIdFromArray('resultListSeoNavigatorLink', widget.contextLinkList)?.relativePath)}
                onClick={onClickLink}
                onClickButton={() => {
                    trackEvent('nearbyWidgetInteraction')
                    trackEvent('nearbyWidgetShowMoreClick')
                    callActionEvent('ad_widget_show_all_button_click', taggingData, widgetAndPage)
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                css={css`
                    ${(p) => p.theme.media.only.tablet} {
                        margin-right: -${(p) => p.theme.space.m}px;
                    }
                `}
                numberOfAdsLimit={numberOfAdsLimit}
                variant="portrait"
            >
                {widget.ads.slice(0, numberOfAdsLimit).map((ad, adIndex) => {
                    return (
                        <CdcAdvertSummaryDiscriminator
                            ad={ad}
                            key={ad.id}
                            testIdPrefix="nearby"
                            onClickTaggingEvent={() => {
                                callActionEvent('ad_widget_ad_click', taggingData, {
                                    ...widgetAndPage,
                                    adIndex: adIndex + 1,
                                    adId: ad.id,
                                    listName: widgetPlaceholder.pulseMetadata?.listName,
                                })
                                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                                trackEvent('nearbyWidgetInteraction')
                                trackEvent('nearbyWidgetAdClick')
                            }}
                        />
                    )
                })}
            </HorizontalSlider>
        </FeedWidgetWrapper>
    )
}

/*
  We only want to show the prompt (that asks for permission to show the location)
  when the user clicks on the "Standort freigeben"-button, not after page-load
  Therefore we have to check if the location was already granted in the past to load
  the nearby-ads directly after page-load.

  navigator.permissions is not supported in all major browsers
  Therefore we are using sessionStorage as a fallback,
  with the problem that the grant-status of the browser and our sessionStorage can differ
 */
const getLocationGrantStatus = async (): Promise<PermissionState> => {
    if (navigator.permissions) {
        const permission = await navigator.permissions.query({
            name: 'geolocation',
        })

        return permission.state
    } else {
        if (storageAvailable('sessionStorage')) {
            const item = sessionStorage.getItem(GEO_LOCATION_GRANTED)
            if (item) {
                return item as PermissionState
            }
        }

        return 'prompt'
    }
}

const widgetAndPage = {
    taggingWidgetType: 'NearbyWidget',
    source: 'homepage',
    page: 'Startpage',
}
