import { CdcAd, LastViewedAdsSliderType } from '@bbx/common/types/UserFeed'
import React, { FunctionComponent, ReactElement, RefObject } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { HorizontalSlider, ITEM_HEIGHT_BAP_APPROX } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { css } from 'styled-components'
import { CdcAdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryDiscriminator'
import { Heading } from '@wh-components/core/Heading/Heading'
import { containsOnlyAdsFromVertical } from '@bbx/search-journey/common/components/AdvertSummary/containsOnlyAdsFromVertical'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'

interface LastViewedAdsWidgetSliderProps {
    slider?: LastViewedAdsSliderType
    heading?: ReactElement
    onClickAdTagging: (ad: CdcAd, adIndex: number) => void
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    numberOfAdsLimit: number
}

export const LastViewedAdsSlider: FunctionComponent<LastViewedAdsWidgetSliderProps & SpaceProps> = ({
    slider,
    onClickAdTagging,
    heading,
    visibilityTrackingRef,
    numberOfAdsLimit,
    ...props
}) => {
    if (!slider || slider.ads.length === 0) {
        return null
    }
    const isOnlyBap = containsOnlyAdsFromVertical(verticalIdMap.BAP, slider.ads)

    return (
        <HorizontalSlider
            testIdPrefix="nearby-widget"
            heading={heading ?? <Heading level={2} text={slider.title} fontSize="l" />}
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            css={css`
                ${(p) => p.theme.media.only.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
            visibilityTrackingRef={visibilityTrackingRef}
            numberOfAdsLimit={numberOfAdsLimit}
            buttonHeight={isOnlyBap ? ITEM_HEIGHT_BAP_APPROX : undefined}
            {...props}
            variant={isOnlyBap ? 'portrait' : 'landscape'}
        >
            {slider.ads.slice(0, numberOfAdsLimit).map((ad, adIndex) => {
                return (
                    <CdcAdvertSummaryDiscriminator
                        ad={ad}
                        key={ad.id}
                        testIdPrefix="nearby"
                        onClickTaggingEvent={() => onClickAdTagging(ad, adIndex)}
                        variant={isOnlyBap ? 'portrait' : 'landscape'}
                        jobsVariant="high"
                    />
                )
            })}
        </HorizontalSlider>
    )
}
