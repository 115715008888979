import React, { FunctionComponent, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { AzaRow } from '@bbx/search-journey/sub-domains/start-page/components/AzaRow/AzaRow'
import { FeedWidgetWrapper } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { Heading } from '@wh-components/core/Heading/Heading'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { Text } from '@wh-components/core/Text/Text'
import { BaseWidget } from '@bbx/common/types/UserFeed'

type Props = {
    widget: BaseWidget
}

export const WelcomeWidget: FunctionComponent<Props> = ({ widget }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']
    const taggingData = useFeedEmptyTaggingData()

    return (
        <FeedWidgetWrapper type={widget.type}>
            <Box display="flex" flexDirection={{ phone: 'column', tablet: 'row' }}>
                <Box flexBasis={{ tablet: '380px' }} paddingRight={{ phone: 'm' }}>
                    <Heading level={2} text="Ganz willhaben nutzen" fontSize="l" marginBottom="s" color={titleColor} />
                    <Text as="p" fontSize="s" color={color ?? 'palette.raccoon'}>
                        Log dich ein oder registriere dich, um ganz <br />
                        willhaben mit Merkliste, Suchagent, Suchverlauf <br /> und Anzeigenaufgabe zu nutzen!
                    </Text>
                    <LinkWithButtonStyle
                        href="/iad/myprofile/login?r=%2Fiad"
                        onClick={() => callActionEvent('startpage_welcome_login', taggingData)}
                        width={{ phone: '100%', tablet: 'auto' }}
                        paddingHorizontal="xxxl"
                        marginTop={{ phone: 's', tablet: 'sm' }}
                    >
                        Jetzt einloggen/registrieren
                    </LinkWithButtonStyle>
                </Box>
                <AzaRow azaUrl="/iad/anzeigenaufgabe" taggingEvent="startpage_welcome_ad_insertion" />
            </Box>
        </FeedWidgetWrapper>
    )
}
