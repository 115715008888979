import React, { Fragment, FunctionComponent, RefObject, useContext } from 'react'
import { CdcSearchAgent, SearchAgentWidgetType } from '@bbx/common/types/UserFeed'
import { HorizontalSlider, ITEM_HEIGHT_JOBS } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { css } from 'styled-components'
import { CdcAdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryDiscriminator'
import {
    DividerWithNegativeMargin,
    FeedWidgetWrapper,
} from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import SearchagentIcon from '@wh-components/icons/Searchagent'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { getPulseAdId, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Avatar } from '@wh-components/core/Avatar/Avatar'
import { Badge } from '@wh-components/core/Badge/Badge'
import { AnchorLink, ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { numberOfAdsLimit } from '@bbx/search-journey/sub-domains/start-page/components/widget/numberOfAdsLimit'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { verticalIdMap, verticalTaggingNameMap } from '@wh/common/chapter/types/verticals'

interface SearchAgentWidgetProps {
    widget: SearchAgentWidgetType
}

export const SearchAgentWidget: FunctionComponent<SearchAgentWidgetProps> = ({ widget }) => {
    return (
        <FeedWidgetWrapper
            heading={widget.title}
            backgroundColor="palette.coral"
            icon={SearchagentIcon}
            linkText="zur Übersicht"
            href="/iad/myprofile/mysearches"
            id={`widget-index-${widget.listIndex}`}
            type={widget.type}
        >
            {widget.searchAgents.map((searchAgent, index) => {
                return (
                    <Fragment key={searchAgent.keyword}>
                        <SearchAgentWidgetSlider searchAgent={searchAgent} />
                        {index !== widget.searchAgents.length - 1 && <DividerWithNegativeMargin marginVertical="m" />}
                    </Fragment>
                )
            })}
        </FeedWidgetWrapper>
    )
}

export const SearchAgentWidgetSlider: FunctionComponent<{ searchAgent: CdcSearchAgent }> = ({ searchAgent }) => {
    const trackEvent = useOptimizelyTrack()
    const taggingData = useFeedEmptyTaggingData()
    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'SearchAgentWidget',
        adIds: searchAgent.ads.map((ad) => getPulseAdId(ad)).slice(0, numberOfAdsLimit),
        pulseMetadata: searchAgent.pulseMetadata,
    })

    const onClickLink = () => {
        callActionEvent('ad_widget_show_all_click', taggingData, getWidgetAndPage(searchAgent.verticalId))
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(searchAgent.verticalId))
        trackEvent('searchAgentWidgetInteraction')
        trackEvent('searchAgentWidgetShowMoreClick')
    }
    const resultListWebLink = findContextLinkWithIdFromArray('resultListSeoNavigatorLink', searchAgent.contextLinkList)?.uri
    const sellerProfileWeblink = findContextLinkWithIdFromArray('sellerProfileWebLink', searchAgent.contextLinkList)?.uri

    const href = sellerProfileWeblink ?? resultListWebLink

    return (
        <HorizontalSlider
            testIdPrefix="search-agents-widget"
            heading={
                <SearchAgentWidgetSubHeader
                    searchAgent={searchAgent}
                    onClick={onClickLink}
                    href={href}
                    visibilityTrackingRef={visibilityTrackingRef}
                />
            }
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            href={href}
            onClick={onClickLink}
            onClickButton={() => {
                trackEvent('searchAgentWidgetInteraction')
                trackEvent('searchAgentWidgetShowMoreClick')
                callActionEvent('ad_widget_show_all_button_click', taggingData, getWidgetAndPage(searchAgent.verticalId))
                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(searchAgent.verticalId))
            }}
            css={css`
                ${(p) => p.theme.media.only.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
            variant={searchAgent.verticalId === verticalIdMap.BAP ? 'portrait' : undefined}
            numberOfAdsLimit={numberOfAdsLimit}
            buttonHeight={searchAgent.verticalId === verticalIdMap.JOB ? ITEM_HEIGHT_JOBS : undefined}
        >
            {searchAgent.ads.slice(0, numberOfAdsLimit).map((ad, adIndex) => {
                return (
                    <CdcAdvertSummaryDiscriminator
                        ad={ad}
                        key={ad.id}
                        testIdPrefix="search-agent"
                        onClickTaggingEvent={() => {
                            callActionEvent('ad_widget_ad_click', taggingData, {
                                ...getWidgetAndPage(searchAgent.verticalId),
                                adId: getPulseAdId(ad),
                                adIndex: adIndex + 1,
                                listName: searchAgent.pulseMetadata?.listName,
                            })
                            callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(searchAgent.verticalId))
                            trackEvent('searchAgentWidgetInteraction')
                            trackEvent('searchAgentWidgetAdClick')
                        }}
                    />
                )
            })}
        </HorizontalSlider>
    )
}

export const SearchAgentWidgetSubHeader: FunctionComponent<{
    searchAgent: CdcSearchAgent
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    href?: string
    onClick?: () => void
}> = ({ searchAgent, visibilityTrackingRef, href, onClick }) => {
    const sellerProfileImageUri = findContextLinkWithIdFromArray('sellerProfileImageUri', searchAgent.contextLinkList)?.uri
    const advertisingState = useContext(AdvertisingStateContext)
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']
    const isJobs = searchAgent.verticalId === verticalIdMap.JOB

    return (
        <Box display="flex" ref={visibilityTrackingRef}>
            {sellerProfileImageUri && (
                <ServerRoutingAnchorLink type="anchor" href={href} marginRight="s" maxHeight="40px">
                    <Avatar size={40} src={sellerProfileImageUri} />
                </ServerRoutingAnchorLink>
            )}
            <Box display="flex" flexDirection="column">
                <Box display="flex">
                    <AnchorLink
                        clientSideRouting={!isJobs}
                        color={titleColor ?? 'palette.verydarkgrey'}
                        href={href}
                        onClick={onClick}
                        type="anchor"
                        css={css`
                            outline: 0;
                        `}
                    >
                        <Text lineClamp={1} fontWeight="bold">
                            {searchAgent.keyword}
                        </Text>
                    </AnchorLink>
                    {searchAgent.infoText && (
                        <Badge
                            paddingVertical="0"
                            marginLeft="s"
                            marginRight="s"
                            fontSize="xs"
                            fontWeight="semibold"
                            color1="palette.coral"
                        >
                            {searchAgent.infoText}
                        </Badge>
                    )}
                </Box>
                <AdNumber numberOfAds={searchAgent.numberOfAdsOfSeller} />
            </Box>
        </Box>
    )
}

const AdNumber: FunctionComponent<{ numberOfAds: number }> = ({ numberOfAds }) => {
    let message
    if (numberOfAds === 0) {
        return null
    } else if (numberOfAds === 1) {
        message = '1 Anzeige'
    } else {
        message = `${numberOfAds} Anzeigen`
    }
    return (
        <Box>
            <Text display="block" color="palette.raccoon" fontSize="s">
                {message}
            </Text>
        </Box>
    )
}

const getWidgetAndPage = (verticalId: number) => ({
    taggingId: verticalTaggingNameMap[verticalId],
    taggingWidgetType: 'SearchAgentWidget',
    source: 'homepage',
    page: 'Startpage',
})
