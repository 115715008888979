import { TeaserImageType } from '@bbx/common/types/UserFeed'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { Box } from '@wh-components/core/Box/Box'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import React, { FunctionComponent, RefObject, useContext } from 'react'
import { css } from 'styled-components'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'

interface Props {
    image: TeaserImageType
    mobileHeight: number
    index: number
    imageRef?: RefObject<HTMLDivElement>
}

export const TeaserImage: FunctionComponent<Props> = ({ image, mobileHeight, index, imageRef }) => {
    const tag: `TeaserImage_${string}` = `TeaserImage_${image.taggingId}`

    const { visibilityTrackingRef } = useWidgetTagging({ taggingWidgetType: tag })
    const taggingData = useFeedEmptyTaggingData()

    const webLink = findContextLinkWithIdFromArray('webLink', image.contextLinkList)

    const imageHeight = Math.ceil(mobileHeight * 1.5)

    const x1Large = `${image.imageLarge}/m/0x400/filters:quality(90)`
    const x2Large = `${image.imageLarge}/m/0x800/filters:quality(90)`
    const x1Small = `${image.imageSmall}/m/0x${imageHeight}/filters:quality(85)`
    const x2Small = `${image.imageSmall}/m/0x${imageHeight * 2}/filters:quality(85)`

    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    const handleTeaserClick = () => {
        callActionEvent('startpage_teaser_click', taggingData, {
            adIndex: index,
            label: image.taggingId,
        })

        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, {
            taggingWidgetType: tag,
            source: 'homepage',
            page: 'Startpage',
        })

        trackCustomDmpEvent('UserAction', { eventName: 'TeaserClick', teaserId: image.taggingId })
    }

    return (
        <ClientRoutingAnchorLink
            type="anchor"
            href={webLink?.uri}
            onClick={handleTeaserClick}
            height={{ phone: mobileHeight, tablet: 250 }}
            minWidth="100%"
            target={image.openInNewTab ? '_blank' : undefined}
            css={css`
                scroll-snap-align: center;
            `}
        >
            <Box
                ref={imageRef}
                width={{
                    tablet: '100%',
                }}
                height="100%"
                css={css`
                    background: image-set(url('${x1Small}') 1x, url('${x2Small}') 2x) no-repeat;
                    background-position: center, center;
                    background-size: cover;

                    ${(p) => p.theme.media.tablet} {
                        background: image-set(url('${x1Large}') 1x, url('${x2Large}') 2x) no-repeat;
                        background-position: right, center;
                        background-size: cover;
                    }
                `}
                role="img"
                aria-label={image.alt}
                position="relative"
            >
                <Box aria-hidden={true} position="absolute" top="45%" left="45%" ref={visibilityTrackingRef}>
                    &nbsp;
                </Box>
            </Box>
        </ClientRoutingAnchorLink>
    )
}
