import React, { FunctionComponent, useContext } from 'react'
import { JobsWidgetType } from '@bbx/common/types/UserFeed'
import { FeedWidgetWrapper } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import MyjobIcon from '@wh-components/icons/Myjob'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { getPulseAdId, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { JobsWidgetSlider } from '@bbx/search-journey/common/components/Widgets/JobsWidgetSlider'
import { numberOfAdsLimit } from '@bbx/search-journey/sub-domains/start-page/components/widget/numberOfAdsLimit'

dayjs.extend(duration)

interface JobsWidgetProps {
    widget: JobsWidgetType
    isHidden: boolean
    onHide: () => void
}

export const JobsWidget: FunctionComponent<JobsWidgetProps> = ({ widget, isHidden, onHide }) => {
    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'JobsWidget',
        adIds: widget.ads.map((ad) => getPulseAdId(ad)).slice(0, numberOfAdsLimit),
        pulseMetadata: widget.pulseMetadata,
        additionalInfoJobs: widget.additionalInfo,
    })
    const { trackCustomDmpEvent } = useContext(DmpStateContext)
    const taggingData = useFeedEmptyTaggingData()

    if (!widget || widget.ads?.length === 0) {
        return null
    }

    if (isHidden) {
        return null
    }

    return (
        <FeedWidgetWrapper
            id={`widget-index-${widget.listIndex}`}
            visibilityTrackingRef={visibilityTrackingRef}
            heading={widget.title}
            icon={MyjobIcon}
            backgroundColor="palette.primary.main"
            onClose={() => {
                onHide()

                callActionEvent('startpage_hide_jobs_widget_click', taggingData)

                trackCustomDmpEvent('UserAction', { eventName: 'TapCloseJobWidget' })
            }}
            type={widget.type}
        >
            <JobsWidgetSlider
                widget={widget}
                onClickAdTagging={(ad, adIndex) => {
                    callActionEvent('ad_widget_ad_click', taggingData, {
                        ...widgetAndPage,
                        adId: getPulseAdId(ad),
                        adIndex: adIndex + 1,
                        listName: widget?.pulseMetadata?.listName,
                        additionalInfoJobs: widget?.additionalInfo,
                    })
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                onClickShowAllTagging={() => {
                    callActionEvent('ad_widget_show_all_click', taggingData, widgetAndPage)
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                onClickShowAllButtonTagging={() => {
                    callActionEvent('ad_widget_show_all_button_click', taggingData, widgetAndPage)
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                numberOfAdsLimit={numberOfAdsLimit}
                numberOfAdsToDisplay={5}
            />
        </FeedWidgetWrapper>
    )
}

const widgetAndPage = {
    taggingWidgetType: 'JobsWidget',
    source: 'homepage',
    page: 'Startpage',
}
