import React, { FunctionComponent } from 'react'
import {
    BaseWidget,
    BubbleListWidgetType,
    FolderWidgetType,
    JobsWidgetType,
    LastSearchesWidgetType,
    LastViewedAdsWidgetType,
    NearbyWidgetPlaceholderType,
    PopularCategoriesWidgetType,
    RecommendationWidgetType,
    SearchAgentWidgetType,
    SellerRecommendationWidgetType,
    TrendSliderWidgetType,
} from '@bbx/common/types/UserFeed'
import { LastSearchesWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/LastSearchesWidget'
import { FolderWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/FolderWidget'
import { SearchAgentWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/SearchAgentWidget'
import { RecommendationWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/RecommendationWidget'
import { NearbyWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/NearbyWidget'
import { JobsWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/JobsWidget'
import { ContentAdWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/ContentAdWidget'
import { WelcomeWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/WelcomeWidget'
import { VerticalsWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/VerticalsWidget'
import { PopularCategoriesWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/PopularCategoriesWidget'
import { ErrorWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/ErrorWidget'
import { LastViewedAdsWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/LastViewedAdsWidget'
import { TrendSliderWidget } from '@bbx/search-journey/sub-domains/start-page/components/widget/TrendSliderWidget'
import { BubbleListWidget } from './widget/BubbleListWidget'
import { SellerRecommendationWidget } from './widget/SellerRecommendationWidget'

interface WidgetDiscriminatorProps {
    widget: BaseWidget
    isJobsWidgetHidden: boolean
    onHideJobsWidget: () => void
}

export const WidgetDiscriminator: FunctionComponent<WidgetDiscriminatorProps> = ({ widget, isJobsWidgetHidden, onHideJobsWidget }) => {
    if (widget.type === 'LAST_SEARCH') {
        return <LastSearchesWidget widget={widget as LastSearchesWidgetType} />
    } else if (widget.type === 'WELCOME_WEB') {
        return <WelcomeWidget widget={widget} />
    } else if (widget.type === 'USER_FOLDERS') {
        return <FolderWidget widget={widget as FolderWidgetType} />
    } else if (widget.type === 'SEARCH_AGENT') {
        return <SearchAgentWidget widget={widget as SearchAgentWidgetType} />
    } else if (widget.type === 'RECOMMENDATIONS') {
        return <RecommendationWidget widget={widget as RecommendationWidgetType} />
    } else if (widget.type === 'VERTICALS') {
        return <VerticalsWidget widget={widget} />
    } else if (widget.type === 'NEARBY_PLACEHOLDER') {
        return <NearbyWidget widgetPlaceholder={widget as NearbyWidgetPlaceholderType} />
    } else if (widget.type === 'JOBS_RECOMMENDATIONS') {
        return <JobsWidget widget={widget as JobsWidgetType} isHidden={isJobsWidgetHidden} onHide={onHideJobsWidget} />
    } else if (widget.type === 'CONTENT_AD') {
        return <ContentAdWidget widget={widget} />
    } else if (widget.type === 'POPULAR_CATEGORIES') {
        return <PopularCategoriesWidget widget={widget as PopularCategoriesWidgetType} />
    } else if (widget.type === 'LAST_VIEWED_ADS') {
        return <LastViewedAdsWidget widget={widget as LastViewedAdsWidgetType} />
    } else if (widget.type === 'TREND_SLIDER_WIDGET') {
        return <TrendSliderWidget widget={widget as TrendSliderWidgetType} />
    } else if (widget.type === 'BUBBLE_LIST') {
        return <BubbleListWidget widget={widget as BubbleListWidgetType} />
    } else if (widget.type === 'SELLER_RECOMMENDATION') {
        return <SellerRecommendationWidget widget={widget as SellerRecommendationWidgetType} />
    } else if (widget.type === 'ERROR') {
        return <ErrorWidget widget={widget} />
    }

    return null
}
