import React, { Fragment, FunctionComponent, useRef } from 'react'
import {
    DividerWithNegativeMargin,
    FeedWidgetWrapper,
} from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import FavoriteHeart from '@wh-components/icons/FavoriteHeart'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { TREND_SLIDER_ICON_BACKGROUND_COLOR, TrendSlider } from '@bbx/search-journey/sub-domains/start-page/components/widget/TrendSlider'
import { TrendSliderWidgetType } from '@bbx/common/types/UserFeed'

interface TrendSliderWidgetProps {
    widget: TrendSliderWidgetType
}

export const TrendSliderWidget: FunctionComponent<TrendSliderWidgetProps> = ({ widget }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [isInView] = useIsInView(ref, '100px')

    return (
        <FeedWidgetWrapper
            marginBottom="l"
            width="100%"
            heading={widget.title}
            icon={FavoriteHeart}
            backgroundColor={TREND_SLIDER_ICON_BACKGROUND_COLOR}
            id={`widget-index-${widget.listIndex}`}
            visibilityTrackingRef={ref}
            type={widget.type}
        >
            {widget.sliders.map((trendSlider, index) => {
                return (
                    <Fragment key={`${trendSlider.title}${index}`}>
                        <TrendSlider
                            trendSlider={trendSlider}
                            isInView={isInView}
                            titleWithIcon={!widget.title && widget.sliders.length === 1}
                        />
                        {index !== widget.sliders.length - 1 && <DividerWithNegativeMargin marginVertical="m" />}
                    </Fragment>
                )
            })}
        </FeedWidgetWrapper>
    )
}
