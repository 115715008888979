import { BubbleList, BubbleListItem } from '@wh/common/chapter/components/BubbleList/BubbleList'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { BubbleListWidgetItem, BubbleListWidgetType } from '@bbx/common/types/UserFeed'
import { FeedWidgetWrapper, WidgetHeader } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { Box } from '@wh-components/core/Box/Box'
import BrandTag from '@wh-components/icons/BrandTag'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import React, { FunctionComponent } from 'react'

interface BubbleListWidgetProps {
    widget: BubbleListWidgetType
}

export const BubbleListWidget: FunctionComponent<BubbleListWidgetProps> = ({ widget }) => {
    const getLinkFromItem = (item: BubbleListWidgetItem) => {
        const webLink = findContextLinkWithIdFromArray('webLink', item.contextLinkList)
        return webLink?.uri ?? ''
    }

    return (
        <FeedWidgetWrapper type={widget.type}>
            <WidgetHeader icon={BrandTag} backgroundColor="palette.primary.main" heading={widget.title} />
            <Box marginTop="s">
                <BubbleList>
                    {widget.items.map((item, index) => {
                        const link = getLinkFromItem(item)

                        if (!link) {
                            return null
                        }

                        return (
                            <BubbleListItem
                                key={index}
                                url={link}
                                onClick={() => {
                                    callActionEvent('startpage_bubble_list_click', undefined, {
                                        list_label: widget.taggingId ? widget.taggingId : widget.title,
                                        item_label: item.taggingId ? item.taggingId : item.label,
                                    })
                                }}
                            >
                                {item.label}
                            </BubbleListItem>
                        )
                    })}
                </BubbleList>
            </Box>
        </FeedWidgetWrapper>
    )
}
