import { PulseMetadata } from '@bbx/common/types/UserFeed'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { BapAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/BapAdvertSummaryComponent'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { Avatar } from '@wh-components/core/Avatar/Avatar'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { HorizontalSlider } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent, useContext } from 'react'
import { css } from 'styled-components'
import { SellerRecommendation } from './SellerRecommendationWidget'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'

interface Props {
    sellerRecommendation: SellerRecommendation
    pulseMetadata?: PulseMetadata
    index: number
}

export const SellerRecommendationSlider: FunctionComponent<Props> = ({ sellerRecommendation, pulseMetadata, index }) => {
    const profileLink = findContextLinkWithIdFromArray('sellerProfile', sellerRecommendation.contextLinks)
    const taggingData = useFeedEmptyTaggingData()

    const trackEvent = useOptimizelyTrack()

    const { visibilityTrackingRef } = useWidgetTagging({
        taggingWidgetType: 'SellerRecommendationWidget',
        adIds: sellerRecommendation.ads.map((ad) => ad.id),
        pulseMetadata: pulseMetadata,
        additionalInfoObject: {
            target: 'widget',
            seller: sellerRecommendation.id,
            sellerRank: index + 1,
        },
    })

    const advertisingState = useContext(AdvertisingStateContext)
    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']
    const accentColor = advertisingState.pageModifications.foregroundColors?.['startpage-accent-color']

    const handleLinkClick = () => {
        callActionEvent('ad_widget_show_all_click', taggingData, {
            ...widgetAndPage,
            listName: pulseMetadata?.listName,
            transactionId: pulseMetadata?.transactionId,
            additionalInfoObject: { target: 'showAllLink', seller: sellerRecommendation.id, sellerRank: index + 1 },
            widgetInteractionId: location.href,
            forcePulse: true,
        })
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
        trackEvent('recommendationsShowMoreClick')
        trackEvent('recommendationsInteraction')
    }

    const handleButtonClick = () => {
        callActionEvent('ad_widget_show_all_button_click', taggingData, {
            ...widgetAndPage,
            listName: pulseMetadata?.listName,
            transactionId: pulseMetadata?.transactionId,
            additionalInfoObject: { target: 'showAllButton', seller: sellerRecommendation.id, sellerRank: index + 1 },
            widgetInteractionId: location.href,
            forcePulse: true,
        })
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
        trackEvent('recommendationsShowMoreButtonClick')
        trackEvent('recommendationsInteraction')
    }

    const handleAdClick = (adIndex: number, ad: AdvertSummary) => {
        callActionEvent('ad_widget_ad_click', taggingData, {
            ...widgetAndPage,
            adId: ad.id,
            adIndex: adIndex + 1,
            listName: pulseMetadata?.listName,
            transactionId: pulseMetadata?.transactionId,
            additionalInfoObject: {
                target: 'ad',
                seller: sellerRecommendation.id,
                sellerRank: index + 1,
            },
        })
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
        trackEvent('recommendationsAdClick')
        trackEvent('recommendationsInteraction')
    }

    return (
        <HorizontalSlider
            testIdPrefix="trends"
            href={profileLink?.uri}
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            onClick={handleLinkClick}
            onClickButton={handleButtonClick}
            heading={
                <SellerRecommendationHeader
                    sellerRecommendation={sellerRecommendation}
                    taggingData={taggingData}
                    pulseMetadata={pulseMetadata}
                    sellerIndex={index}
                />
            }
            variant="portrait"
            marginTop="m"
            visibilityTrackingRef={visibilityTrackingRef}
        >
            {sellerRecommendation.ads.map((ad, adIndex) => (
                <BapAdvertSummaryComponent
                    key={ad.id}
                    advertSummary={ad}
                    onClickTaggingEvent={() => handleAdClick(adIndex, ad)}
                    variant="portrait"
                    color={color}
                    accentColor={accentColor}
                />
            ))}
        </HorizontalSlider>
    )
}

interface SellerRecommendationWidgetProps {
    sellerRecommendation: SellerRecommendation
    taggingData: TaggingData
    pulseMetadata?: PulseMetadata
    sellerIndex: number
}

const SellerRecommendationHeader: FunctionComponent<SellerRecommendationWidgetProps> = ({
    sellerRecommendation,
    taggingData,
    pulseMetadata,
    sellerIndex,
}) => {
    const trackEvent = useOptimizelyTrack()

    const advertisingState = useContext(AdvertisingStateContext)
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']

    const sellerProfileImageUri = findContextLinkWithIdFromArray('sellerProfileImageUri', sellerRecommendation.contextLinks)?.uri
    const sellerProfile = findContextLinkWithIdFromArray('sellerProfile', sellerRecommendation.contextLinks)?.uri

    const handleClick = () => {
        callActionEvent('ad_widget_show_all_click', taggingData, {
            ...widgetAndPage,
            listName: pulseMetadata?.listName,
            transactionId: pulseMetadata?.transactionId,
            additionalInfoObject: { target: 'sellerHeader', seller: sellerRecommendation.id, sellerRank: sellerIndex + 1 },
            widgetInteractionId: location.href,
            forcePulse: true,
        })
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
        trackEvent('recommendationsSellerHeaderClick')
        trackEvent('recommendationsInteraction')
    }

    return (
        <ClientRoutingAnchorLink
            href={sellerProfile}
            type="anchor"
            color={titleColor ?? 'palette.verydarkgrey'}
            onClick={handleClick}
            display="flex"
            alignItems="center"
            gap="s"
            css={css`
                font-weight: bold;
            `}
        >
            <Avatar src={sellerProfileImageUri} size={22} />
            {sellerRecommendation.name}
        </ClientRoutingAnchorLink>
    )
}

const widgetAndPage = {
    taggingWidgetType: 'SellerRecommendationWidget',
    source: 'homepage',
    page: 'Startpage',
}
